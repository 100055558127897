import { isSameDay, format, sub } from 'date-fns'
import { enUS, ru } from 'date-fns/locale'

export function getSiteLocaleForDate(locale: string) {
  const locales: any = { enUS, ru }
  const siteLocale = locale === 'ru' ? 'ru' : 'enUS'
  return locales[siteLocale]
}

// format - https://date-fns.org/v3.6.0/docs/format
export function getFormattingDate(locale: string, date: string | number | undefined, formatStr = 'P' ) {
  const time = ref<string | null>(null)
  if (date  && new Date(date)) {
    time.value = format(new Date(date), formatStr, { locale: getSiteLocaleForDate(locale)})
  }
  return time.value ? time.value : null
}

export function getTimeForCalendar( date: Date) {
  const  dt = format(date, 'dd')
  const month = format(date, 'LL')
  const year = format(date, 'uu')
  return `${year}-${month}-${dt}`
}

export function subDayFromDate(date: Date, days: number) {
  return sub(date, {days})
}

export function testIsNextDate(idx: number, currentDate: number | null | undefined , prevDate: number | null | undefined ) {
  if (idx === 0) return true
  if (currentDate && prevDate) {
    return !isSameDay(new Date(currentDate), new Date(prevDate))
  }
  return false
}
